@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Russo+One&display=swap");

html {
   font-size: 16px;
}

body {
   font-family: "Montserrat", sans-serif;
}

header {
   background: linear-gradient(90deg, rgba(248, 249, 250, 1) 30%, rgba(0, 0, 0, 0) 50%, rgba(248, 249, 250, 1) 70%);
   position: sticky;
   top: 0;
   z-index: 2000;
}

#javascript-images img{
   max-width: 300px;
}

#lang-selector {
   box-shadow: 0px 0px 10px #c7c7c7 !important;
   transition: box-shadow ease-in-out 150ms;
}

#lang-selector:hover {
   box-shadow: 0px 0px 10px #89000e !important;
   transition-duration: 300ms;
}

#lang-selector span {
   background-color: transparent;
   transition: all ease-in-out 150ms;
}

#lang-selector span.active {
   background-color: #89000e;
   box-shadow: 0px 0px 10px #89000e !important;
   transition-duration: 300ms;
}

.App {
   background-color: #fff8f9;
}

.estol-bg-danger {
   background-color: #89000e;
}

.estol-bg-danger-linear-vertical {
   background: linear-gradient(#fff8f9 0%, #89000e 35%, #89000e 65%, #fff8f9 100%);
}

.estol-bg-danger-linear-vertical-top {
   background: linear-gradient(#fff8f9 0%, #89000e 35%, #89000e 100%);
}

.btn.estol-btn-danger {
   border-color: #89000e;
   color: #f8f9fa;
   background-color: #89000e;
   transition: all ease-in-out 150ms;
}

.btn.estol-btn-outline-danger {
   border-color: #89000e;
   color: #89000e;
   background-color: #f8f9fa;
   transition: all ease-in-out 150ms;
}

.btn.estol-btn-danger:hover,
.btn.estol-btn-danger:active {
   background-color: #a70011;
   border-color: #a70011;
   box-shadow: 0px 0px 10px #a70011;
   transition-duration: 300ms;
}

.btn.estol-btn-outline-danger:hover,
.btn.estol-btn-outline-danger:active {
   background-color: #89000e;
   box-shadow: 0px 0px 10px #89000e;
   color: #f8f9fa;
   transition-duration: 300ms;
}

.estol-btn-outline-danger.alt:hover,
.estol-btn-outline-danger.alt:active {
   border-color: #f8f9fa;
   transition: all 300ms;
}

.img-shadow {
   position: absolute;
   top: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(0deg, #f8f9fa 0%, #f8f9fa00 20%, #f8f9fa00 80%, #f8f9fa 100%), linear-gradient(90deg, #f8f9fa 0%, #f8f9fa00 20%, #f8f9fa00 80%, #f8f9fa 100%);
}

.industrie {
   opacity: 0.2;
   z-index: 1;
}

.navbar-brand img {
   height: 3rem;
}

.estol-shadow {
   box-shadow: 2px 2px 10px #89000e;
}

.estol-shadow-light {
   box-shadow: 2px 2px 10px #f8f9fa;
}

.estol-text-danger {
   color: #89000e;
}

@media (max-width: 1199.98px) {
   html {
      font-size: 15px;
   }
   
      .avatar {
         max-width: 260px;
      }
}
@media (max-width: 991.98px) {
   html {
      font-size: 15px;
   }
   
      .avatar {
         max-width: 240px;
      }
}
@media (max-width: 767.98px) {
   html {
      font-size: 14px;
   }
   
      .avatar {
         max-width: 220px;
      }
}
@media (max-width: 575.98px) {
   html {
      font-size: 14px;
   }

   .avatar {
      max-width: 200px;
   }
}

/* // `md` applies to small devices (landscape phones, less than 768px) */

/* // `lg` applies to medium devices (tablets, less than 992px) */

/* // `xl` applies to large devices (desktops, less than 1200px) */

/* // `xxl` applies to x-large devices (large desktops, less than 1400px) */
